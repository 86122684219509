import { Suspense } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useMediaQueryUp } from '@medifind/utils';
import FindADoctorForm from './forms/find-a-doctor-form';
import styles from './Landing.module.scss';

const Landing = ({ titleText, logo }) => {
  const mediumBreakpoint = useMediaQueryUp('lg');

  return (
    <div className={styles['landing']}>
      <Container className={styles['landing-container']}>
        <Row>
          <Col>
            <div className={styles['landing-heading']}>
              <h1 className={'hide-h-tag'}>MediFind - Better care. Faster.</h1>
              {titleText ? (
                <div className={styles['search-header']}>{titleText}</div>
              ) : (
                <div className={styles['search-header']}>The best care starts with the best doctors</div>
              )}
            </div>
            <div className={classNames(styles['search-bar'])}>
              <div className={classNames(styles['search-bar__form'])}>
                <Suspense fallback={<div className="fallback" />}>
                  <FindADoctorForm
                    showWrapper={!mediumBreakpoint}
                    classes={{
                      formContainer: styles['form-container'],
                      formSeperator: styles['form-seperator'],
                      formSubmitButton: styles['form-submit-button'],
                      formSubmitButtonContent: styles['form-submit-button-content'],
                      formInputError: styles['form-input-error'],
                      formInput: styles['form-input'],
                      formInputGroup: styles['form-input-group'],
                      formInputField: styles['form-input-field'],
                      formSecondaryInputField: styles['form-secondary-input-field'],
                      formField: styles['form-field'],
                      formError: styles['form-error'],
                      formDropdown: styles['form-field-dropdown'],
                      icon: styles['form-input-icon'],
                      menuScrollContainer: styles['form-menu-scroll-container'],
                      menuList: styles['form-menu-list'],
                      menuItem: styles['form-menu-item'],
                      menuItemHeader: styles['form-menu-item-header'],
                    }}
                  />
                </Suspense>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
Landing.propTypes = {
  titleText: PropTypes.string,
  logo: PropTypes.string,
};

export default Landing;
