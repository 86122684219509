/* eslint-disable jsx-a11y/media-has-caption */
import { lazy } from 'react';
import { useLazyComponentPreload } from '@medifind/hooks';
import { usePageHooks } from '@medifind/router';
import { SEO } from '@medifind/seo';
import { MFLazyLoad } from '@medifind/shared-basic-components';
import { useMediaQueryDown } from '@medifind/utils';
import { useAuthentication } from '@medifind/zustand';
import Landing from '../components/home/Landing';

const PopularSearch = lazy(() => import('../components/home/PopularSearch'));
const How = lazy(() => import('../components/home/How'));
const Hope = lazy(() => import('../components/home/Hope'));
const Signup = lazy(() => import('../components/home/Signup'));
const BrowseBy = lazy(() => import('../components/home/browse-by/BrowseBy'));
const FeaturedIn = lazy(() => import('../components/home/FeaturedIn'));
const DoctorProfileCardsSection = lazy(() => import('../components/home/DoctorProfileCardsSection'));

/* __ROUTE_PATH__=/ */
const Index = ({ titleText = null, logo = null, searchBarPlaceholder = null }) => {
  usePageHooks();
  useLazyComponentPreload([How, Hope, Signup, FeaturedIn]);
  const isLoggedIn = useAuthentication((state) => state?.profile);
  const isDownSM = useMediaQueryDown('sm');
  const desc =
    'When it comes to your health, nothing is more valuable than time. Quickly find the best doctors, latest medical advances, and active clinical trials with MediFind. Our goal is to dramatically reduce the amount of time and frustration you spend finding the right healthcare, so you have the best possible chances at the best health outcomes.';
  return (
    <>
      <SEO description={desc} title={'MediFind | Find a Doctor'} />
      <Landing titleText={titleText} logo={logo} searchBarPlaceholder={searchBarPlaceholder} />
      <MFLazyLoad height={700}>
        <DoctorProfileCardsSection />
      </MFLazyLoad>
      {isDownSM && (
        <MFLazyLoad height={300}>
          <Hope />
        </MFLazyLoad>
      )}
      <MFLazyLoad height={700}>
        <PopularSearch />
      </MFLazyLoad>
      <MFLazyLoad height={600}>
        <How />
      </MFLazyLoad>
      <MFLazyLoad height={600}>
        <FeaturedIn />
      </MFLazyLoad>
      <MFLazyLoad height={800}>
        <Signup hideSignupButton={!!isLoggedIn} />
      </MFLazyLoad>
      <MFLazyLoad height={800}>
        <BrowseBy />
      </MFLazyLoad>
    </>
  );
};

export default Index;
